import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './aboutus.css'

const AboutUs = () => {

    return (
        // <div style={{ width: '100%' , textAlign:'center' }}>
        <>
            <Helmet>
                <title>ABOUT US - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'จากจุดเริ่มต้นเราได้รับโอกาศร่วมก่อสร้างโรงไฟฟ้าพลังงานแสงอาทิตย์ขนาดใหญ่ถายในประเทศไทย และเราได้ก่อตั้งบริษัทในปี พ.ศ.2560 เราได้ก้าวขึ้นเป็นบริษัทรับเหมางานวิศวกรรมด้านพลังงานของคนไทย ภายใต้ชื่อ บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด'}
                />
            </Helmet>
            <div
                style={{ width: '100%', margin: '20px auto', position: 'relative' }}
            >
                <Row justify={'center'} gutter={20}
                    style={{
                        position: 'absolute',
                        height: '70%',
                        // overflowY: 'scroll',
                        marginRight: '3%',
                        width: '100%',
                    }}
                    className="dis-scroll"
                >
                    <Col span={12}>
                        {/* <img style={{ width: '100%', }} /> */}
                    </Col>
                    <Col span={12} >
                        {/* <div
                            className='c-gold'
                            style={{
                                fontWeight: '600',
                                fontSize: '26px'
                            }}
                        > */}
                        <h1 className='c-gold'
                            style={{
                                fontWeight: '600',
                                fontSize: '26px',
                            }}>
                            บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด
                        </h1>
                        {/* </div> */}
                        <div
                            className='c-gray font-medium'
                            style={{
                                fontSize: '18px',
                                // width:'100%',
                                letterSpacing: '0px',
                                maxWidth: '550px',
                                textAlignLast: 'justify'
                            }}
                        >
                            <section >จากจุดเริ่มต้นที่เราได้รับโอกาสร่วมก่อสร้าง โรงไฟฟ้าพลังงานแสงอาทิตย์</section>
                            <section> ขนาดใหญ่ภายในประเทศไทย และเราได้ก่อตั้งบริษัทในปี พ.ศ. 2560 เรา</section>
                            <section>ได้ก้าวขึ้นมาเป็นบริษัทรับเหมางานวิศวกรรมด้านพลังงานของคนไทย ภาย</section>
                            <section style={{ textAlignLast: 'auto' }}>ใต้ชื่อ <span className='c-gold'>“บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด”</span></section>
                            <br />
                            <div >
                                <section>บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด เป็นบริษัทของคนไทยที่เป็น</section>
                                <section>ผู้นำในด้านวิศวกรรมพลังงาน ก่อตั้งเมื่อวันที่ 10 มกราคม 2560 โดย</section>
                                <section style={{ letterSpacing: '1px' }}>คุณฐิติพันธ์ เตชะสิทธินันท์ ที่จบการศึกษาสาขาวิศวกรรมไฟฟ้าและ</section>
                                <section style={{ letterSpacing: '0.5px' }}>สมาร์ตกริดเทคโนโลยีจากมหาวิทยาลัยนเรศวร โดยเราได้ขยายธุรกิจสู่</section>
                                <section style={{ letterSpacing: '1px' }}>ตลาดงานก่อสร้างแบบ EPC สำหรับกลุ่มธุรกิจด้านพลังงาน เราได้</section>
                                <section style={{ letterSpacing: '1px' }}>ประสบความสำเร็จอย่างมาก โดยได้รับสัญญาโครงการก่อสร้างทั้ง</section>
                                <section style={{ letterSpacing: '0.5px' }}>ภาครัฐและภาคเอกชนมากกว่า 200 โครงการ กำลังผลิตรวมมากกว่า</section>
                                <section style={{ textAlignLast: 'auto' }}>40 เมกะวัตต์</section>
                            </div>

                            <br />
                            <div>
                                <section style={{ letterSpacing: '1px' }}>เรามีปณิธานยิ่งใหญ่เพื่อคนไทยด้วยคำว่า<span className='c-gold'> “พลังงานเพื่อชีวิต” </span>โดย</section>
                                <section style={{ letterSpacing: '0.5px' }}>บริษัทฯ ยังคงรักษามาตรฐาน และการเติบโตในธุรกิจเดิมอย่างมั่นคง</section>
                                <section style={{ letterSpacing: '1px' }}>พร้อมไปกับการขยายสู่ตลาดใหม่ เรามุ่งมั่นที่จะเป็นหนึ่งในบริษัทของ</section>
                                <section style={{ letterSpacing: '1px' }}>คนไทยที่ดีที่สุดด้วยมาตรฐาน คุณภาพ และทักษะเฉพาะทาง พร้อม</section>
                                <section style={{ letterSpacing: '0.5px' }}>ประสบการณ์ที่สั่งสมมา ในการเป็นบริษัทรับเหมาก่อสร้าง ด้านพลังงาน</section>
                                <section>แบบครบวงจรในระดับสากล</section>
                            </div>
                        </div>
                    </Col>
                </Row>
                <img src='/img/Banner/AboutUs.jpg' style={{ width: '100%' }} loading={'lazy'} />
            </div>
            {/* <div
                style={{
                    width: '100%',
                    backgroundColor: '#c6c6c6',
                    height: '500px'
                }}
            >
            </div> */}
            <div
                style={{
                    width: '80%',
                    margin: '40px auto'
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={14}>
                        <div className='fs-30 c-gold font-medium' >
                            {/* <div className='v-cus-head'> */}
                            วิสัยทัศน์
                            {/* </div> */}
                        </div>
                        <div className='fs-20 c-gray' >
                            "เรามุ่งมั่นบริหารงานด้านพลังงาน <br />
                            ด้วยหลักวิศวกรรมมาตรฐานสากล"
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={10}>
                        <div className='fs-30 c-gold font-medium' >
                            {/* <div className='v-cus-head'> */}
                            พันธกิจ
                            {/* </div> */}
                        </div>
                        <div className='fs-20 c-gray' >
                            <ul>
                                <li>
                                    บริหารด้วยความปลอดภัย
                                </li>
                                <li>ปฏิบัติงานตามหลักวิศวกรรม</li>
                                <li>ดำเนินธุรกิจด้วยความซื่อสัตย์ และยุติธรรมกับคู่ค้า</li>
                                <li>สนับสนุนประเทศไทยสู่ Net Zero</li>
                            </ul>

                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                {/* <div className='fs-24 font-medium' style={{ display: 'flex' }} >
                    <div className='v-cus-head'>
                        พันธกิจ
                    </div>
                </div>
                <div className='fs-30 v-cus-body' >
                    &bull; บริหารด้วยความปลอดภัย<br />
                    &bull; ปฏิบัติงานตามหลักวิศวกรรม<br />
                    &bull; ดำเนินธุรกิจด้วยความซื่อสัตย์ และยุติธรรมกับคู่ค้า<br />
                    &bull; สนับสนุนประเทศไทยสู่ Net Zero<br />
                </div> */}
            </div>
        </>

    );
};

export default AboutUs;
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Image, Carousel } from 'antd'
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import './project.css'


const ProjectContainer = ({ MainPath = '', list = [], header = "", type = '' }) => {

    const [showModal, setShowModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const { pathname } = useLocation()
    const preShowModal = (item) => {
        setSelectedItem(item)
        setTimeout(() => {
            setShowModal(true)
        }, 100);
    }
    return (
        <>
            <Helmet>
                <title>PROJECTS ME - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={`โครงการติดตั้ง โซล่าร์เซลล์เพื่อ '${header}'`}
                />
            </Helmet>
            <div style={{
                width: '100%',
                paddingTop: '40px',
                paddingBottom: '40px'
            }}
                className='project-bg'
            >
                <div
                    style={{
                        width: '90%',
                        margin: '0 auto',
                        maxWidth: '1200px'
                    }}
                    className='fs-44'
                >
                    <h1 className='fs-36 font-medium c-white' >
                        {type === 'all' ? <>
                            <span style={{
                                fontWeight: 600
                            }}>โครงการติดตั้ง</span>
                            <br />
                            <span >
                                โซล่าร์เซลล์เพื่อ '{header}' ทั้งหมด
                            </span>
                        </> : <>
                            <span style={{
                                fontWeight: 600
                            }}>โครงการติดตั้ง</span>
                            <br />
                            <span >
                                โซล่าร์เซลล์เพื่อ '{header}'
                            </span>
                        </>
                        }
                    </h1>
                    <br />
                    <Row justify={'space-around'} gutter={[20, 20]} >
                        {
                            list.map((item, i) => (
                                <ImgList2
                                    key={i.toString()}
                                    MainPath={MainPath}
                                    item={item}
                                    onClick={preShowModal}
                                />
                            ))
                        }
                    </Row>
                    {type !== 'all' && <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <a href={`http://www.megawattenergys.com${pathname}/all`}
                            target='_blank' rel="noreferrer"
                            style={{
                                textDecoration: 'none',
                                color:'black'
                            }}
                        >
                            <span className='art-all fs-20'
                                // onClick={() => {
                                //     window.open(`${pathname}/all`)
                                // }}
                                
                                >โครงการทั้งหมด</span>
                        </a>
                    </div>}
                </div>
                <ImgModel
                    visible={{ get: showModal, set: setShowModal }}
                    MainPath={MainPath}
                    item={selectedItem}
                // title={}
                />
            </div>
        </>

    );
};
const ImgList = ({ MainPath, item, onClick = () => { }, }) => {
    return (
        <>
            <Col xs={24} sm={12} lg={8}
                style={{
                    textAlign: 'center',

                }}
            >
                <div
                    style={{
                        backgroundColor: '#fff',
                        paddingBottom: '10px'
                    }}
                    className='img-hover1'
                >
                    <img
                        onClick={() => onClick(item)}
                        src={`${MainPath}${item.path}${item.title}`}
                        style={{ maxWidth: '100%' }} loading={'lazy'}
                        alt={''}
                    />
                    <Row justify={'center'} style={{ textAlign: 'left', maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Col span={24} >
                            <div className={'fs-16 font-medium'} >
                                {item.content && item.content.name}
                            </div>
                            <div className='fs-18 font-semi-bold c-gold'>
                                {item.content && item.content.watt} <span className='fs-16 font-medium' style={{ color: '#000' }}>{item.content && item.content.ext}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </>
    )
}
const ImgList2 = ({ MainPath, item, onClick = () => { }, }) => {
    return (
        <>

            <Col xs={24} sm={12} lg={8}
                style={{
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#fff',
                        paddingBottom: '10px',
                        maxWidth: '400px',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        height: '100%'
                    }}
                    className='img-hover1'
                >
                    <img
                        onClick={() => onClick(item)}
                        src={`${MainPath}/${item.title}`}
                        style={{ maxWidth: '100%' }}
                        loading={'lazy'}
                        alt={''}
                    />
                    <Row justify={'center'} style={{ textAlign: 'left', maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Col span={24} className={'fs-14 font-semi-bold'}  >
                            <span  >{item.content && item.content.name}</span>
                        </Col>
                        <Col span={24} className='fs-18 font-semi-bold c-gold' >
                            <Row wrap={false} >
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    {item.content && item.content.watt}
                                </div>
                                <div className='font-medium cus-address' style={{ color: '#fff' }}>
                                    {item.content && item.content.address}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>
        </>
    )
}




const ImgModel = ({ visible, item = { list: [] }, MainPath = '' }) => {

    const [selectImg, setSelectImg] = useState()
    const carouselRef = useRef()
    useEffect(() => {
        if (visible.get) {
            if (item.list) {
                setSelectImg(item.list[0])
            }
        }
    }, [visible.get])

    return (
        <>

            <Modal
                open={visible.get}
                onCancel={() => visible.set(false)}
                destroyOnClose={true}
                footer={false}
                width={800}
                title={<Row justify={'center'} style={{ textAlign: 'left', maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Col span={24} className={'fs-18 font-semi-bold'}  >
                        <span  >{item.content && item.content.name}   {item.content && item.content.address}</span>
                    </Col>
                    {/* <Col span={24} className='fs-18 font-semi-bold c-gold' >
                            <Row wrap={false} >
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    {item.content && item.content.watt}
                                </div>
                                <div className='font-medium cus-address' style={{ color: '#fff' }}>
                                    {item.content && item.content.address}
                                </div>
                            </Row>
                        </Col> */}
                </Row>}
                // style={{ backgroundColor: 'red' }}
                bodyStyle={{ backgroundColor: 'rgba(255 ,255 ,255 ,0.8)' }}
            >
                <Helmet>
                    <title>{`${item.content && item.content.name} - MEGAWATT ENERGY SOLUTION`}</title>
                    <meta
                        name={'description'}
                        content={`โครงการติดตั้ง โซล่าร์เซลล์ ${item.content && item.content.name}'`}
                    />
                </Helmet>
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <Carousel
                        ref={carouselRef}
                        dots={false}
                        autoplay={true}
                        beforeChange={(current, next) => setSelectImg(item.list[next])}
                    >
                        {item.list && item.list.map((img_name, i) => (
                            <img
                                src={`${MainPath}/${img_name}`}
                                key={i.toString()}
                                loading={'lazy'}
                                alt={''}
                            />
                        ))}
                    </Carousel>
                    {/* <img src={`${MainPath}${item.path}${selectImg}`} style={{ width: '100%' }} /> */}
                    {/* <img src={`${MainPath}${item.path}${selectImg}`} style={{ width: '100%', maxWidth: '450px' }} /> */}
                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', width: '90%', margin: '0 auto', justifyContent: 'center' }} className={'mt-3'}>
                        {item.list && item.list.map((img_name, i) => (
                            <img
                                src={`${MainPath}/${img_name}`}
                                className={`img-hover1 ${selectImg === img_name ? 'border-img1' : ''}`}
                                style={{ maxWidth: '100px', margin: '10px' }}
                                key={i.toString()}
                                onClick={() => carouselRef.current.goTo(i)}
                                alt={''}
                            />
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default ProjectContainer;
import React, { useEffect, useRef } from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
} from 'antd'
import { Helmet } from 'react-helmet';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
// import { GoogleMap, Marker } from "react-google-maps"
import Axios from 'axios';
import './contactus.css'
const { Item } = Form;

const ContactUs = () => {
    const formRef = useRef()

    // const MyMapComponent = (props) => (
    //     <GoogleMap
    //         defaultZoom={8}
    //         defaultCenter={{ lat: -34.397, lng: 150.644 }}
    //     >
    //         {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
    //     </GoogleMap>
    // )
    // useEffect(() => {

    // }, [])
    // window.onloadTurnstileCallback = function () {
    //     turnstile.render('#example-container', {
    //         sitekey: '<YOUR_SITE_KEY>',
    //         callback: function (token) {
    //             console.log(`Challenge Success ${token}`);
    //         },
    //     });
    // };

    // // if using synchronous loading, will be called once the DOM is ready
    // turnstile.ready(onloadTurnstileCallback);

    const handleFinish = async (values) => {
        // console.log(values)
        const response = await Axios({
            method: 'POST',
            // url: 'https://megawattenergys.com/api/sendMail.php',
            url: 'https://api.megawattenergys.com/api/sendMail.php',
            headers: { 'Content-Type': 'application/json' },
            data: values
        })
        console.log(response)
        if (response.data.sent) {
            formRef.current.resetFields()
            alert('ส่งข้อความสำเร็จ')
        } else {
            alert('ส่งข้อความไม่สำเร็จ')
        }
    }

    return (
        <div>
            <Helmet>
                <title>CONTACT US - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'Contact us'}
                    content={'ติดต่อเรา'}
                />
            </Helmet>
            <img src='/img/Banner/contact-us.jpeg' alt='megawatt' style={{ width: '100%' }} loading={'lazy'} />
            <div
                style={{
                    width: '80%',
                    margin: '20px auto'
                }}
            >
                <Row>
                    <Col sm={24} lg={12}>
                        <div style={{ display: 'block', lineHeight: '28px' }}>
                            <h2 className='c-gray fs-30 font-medium'>
                                ติดต่อเรา
                            </h2>
                            <span className='c-gray fs-22 font-light'>
                                บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด
                            </span>
                            <br />
                            <br />
                            <br />
                            <h2 className='c-gray fs-30 font-medium'>
                                ที่ตั้ง
                            </h2>
                            <span className='c-gray fs-22 font-light'>
                                20/15 ม.7 ต.คลองสวนพลู อ.พระนครศรีอยุธยา
                            </span>
                            <br />
                            <span className='c-gray fs-22 font-light'>
                                จ.พระนครศรีอยุธยา 13000
                            </span>
                            <br />
                            <br />
                            <br />
                            <span className='c-gray fs-22 font-light' >
                                <PhoneOutlined style={{ display: 'inline-grid' }} /> +66 (0)35-955389
                            </span>
                            <br />
                            <Row>
                                <Col>
                                    <span className='c-gray fs-22 font-light'>
                                        <MailOutlined style={{ display: 'inline-grid' }} />
                                    </span>
                                </Col>
                                <Col>
                                    <ul className='no-bullet c-gray fs-22 font-light' style={{ marginLeft: '10px' }}>
                                        <li>
                                            info@megawattenergys.com
                                        </li>
                                        <li>
                                            megawattenergys@gmail.com
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col sm={24} lg={12}>
                        <div style={{ width: '100%', borderRadius: '20px', backgroundColor: '#d1a748', padding: ' 30px 10%' }}>
                            <h2 className='c-white fs-26 font-medium'>
                                แบบฟอร์มการส่ง
                            </h2>
                            <Form
                                layout="vertical"
                                onFinish={handleFinish}
                                ref={formRef}
                            // action={'/api/sendmail.php'}
                            >
                                <Item
                                    name={'name'}
                                    label={<span className='fs-20 c-white'>ชื่อ-สกุล</span>}
                                    rules={[{
                                        required: true,
                                        message: 'กรุณาระบุ ชื่อ - นามสกุล'
                                    }]}
                                // style={{ backgroundColor: 'none' }}
                                >
                                    <Input style={{ fontSize: '20px' }} placeholder='Name Surname' autoComplete={'off'} />
                                    {/* <input style={{ backgroundColor: 'none' }} /> */}
                                </Item>
                                <Item
                                    name={'email'}
                                    label={<span className='fs-20 c-white'>อีเมล์</span>}
                                    rules={[{
                                        required: true,
                                        message: 'กรุณาระบุ อีเมล์'
                                    }]}
                                // style={{ backgroundColor: 'none' }}
                                >
                                    <Input style={{ fontSize: '20px' }} placeholder='Your@email.com' autoComplete={'off'} />
                                    {/* <input style={{ backgroundColor: 'none' }} /> */}
                                </Item>
                                <Item
                                    name={'subject'}
                                    label={<span className='fs-20 c-white'>หัวข้อ</span>}
                                // style={{ backgroundColor: 'none' }}
                                >
                                    <Input style={{ fontSize: '20px' }} placeholder='Suabject' autoComplete={'off'} />
                                    {/* <input style={{ backgroundColor: 'none' }} /> */}
                                </Item>
                                <Item
                                    name={'message'}
                                    label={<span className='fs-20 c-white'>รายละเอียด</span>}
                                // style={{ backgroundColor: 'none' }}
                                >
                                    <Input style={{ fontSize: '20px' }} placeholder='Your Massage to us' autoComplete={'off'} />
                                    {/* <input style={{ backgroundColor: 'none' }} /> */}
                                </Item>
                                <div className='checkbox mb-3'>
                                    <div className="cf-turnstile" data-sitekey="0x4AAAAAAADJyH4NCm4FmcCW"></div>
                                </div>
                                <button
                                    htmltype='submit'
                                    className='fs-20 c-gold font-medium span-btn icon-hover'
                                >
                                    ส่งข้อความ
                                </button>
                            </Form>
                            <br />
                            {/* <div>
                                <span className='fs-20 c-gold font-medium span-btn icon-hover'  >ส่งข้อความ</span>
                            </div> */}
                        </div>
                    </Col>
                </Row >
                <div
                    style={{ width: '100%' }}
                >
                    {/* <GoogleMap
                        defaultZoom={8}
                        defaultCenter={{ lat: -34.397, lng: 150.644 }}
                    >
                    </GoogleMap> */}
                    {/* <MyMapComponent /> */}
                </div>
            </div >
        </div >
    );
};

export default ContactUs;
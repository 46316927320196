import React, { useEffect } from 'react';
import {
    Row,
    Col
} from 'antd'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../css/IconHover2.css'

const OurBusiness = () => {



    return (
        <>
            <Helmet>
                <title>BUSINESS - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'โครงสร้างธุรกิจหลักของเมกะวัตต์ แบ่งออกเป็น 3 กลุ่ม ได้แก่ ธุรกิจพลังงาน (Energy Business) ซึ่งประกอบด้วยธุรกิจผลิตไฟฟ้าจากก๊าซธรรมชาต และธุรกิจให้บริการที่เกี่ยวข้องของกลุ่มบริษัทฯ ธุรกิจพลังงานหมุนเวียน และธุระกิจก๊าซ, ธุรกิจโครงสร้างพื้นฐานและสาธารณปโภค (Infrastructure & Utlities Business) และธุรกิจดิจิทัล (Digital Business)'}
                />
            </Helmet>
            <div
                style={{
                    width: '90%',
                    textAlign: 'center',
                    margin: '20px auto'
                }}
            >
                <div
                    className='font-medium fs-36 c-gold'
                >
                    ธุรกิจของเรา
                </div>
                <div
                    className='c-gray font-medium fs-16 '
                    style={{ display: 'block', lineHeight: '28px' }}
                >
                    โครงสร้างธุรกิจหลักของเมกะวัตต์ แบ่งออกเป็น 3 กลุ่ม ได้แก่ ธุรกิจพลังงาน (Energy Business)
                    <br />
                    ธุรกิจระบบโครงข่ายไฟฟ้าอัจฉริยะ (Smart Grid Business) ธุรกิจระบบอัตโนมัติ (Automation Business)
                    {/* <div>
                        โครงสร้างธุรกิจหลักของเมกะวัตต์ แบ่งออกเป็น 3 กลุ่ม ได้แก่ ธุรกิจพลังงาน (Energy Business) ซึ่งประกอบด้วยธุรกิจผลิตไฟฟ้าจากก๊าซธรรมชาติ
                    </div>
                    <div>
                        และธุรกิจให้บริการที่เกี่ยวข้องของกลุ่มบริษัทฯ ธุรกิจพลังงานหมุนเวียน และธุระกิจก๊าซ, ธุรกิจโครงสร้างพื้นฐานและสาธารณปโภค
                    </div>
                    <div>
                        (Infrastructure & Utlities Business) และธุรกิจดิจิทัล (Digital Business)
                    </div> */}
                </div>
                <RenderBusiness />
            </div>
        </>
    );
};
const RenderVR = () => {
    return (
        <Col xs={0} sm={1} style={{ display: 'flex', }}>
            <div
                style={{
                    height: '60%',
                    borderRight: '2px solid',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    position: 'absolute',
                    top: '10%'
                }}
            >
            </div>
        </Col>
    )
}

export const RenderBusiness = ({ imageWidth = '56%' }) => {
    return (<Row justify={'center'} className='c-gray fs-30 font-medium mt-5' >
        <Col xs={24} sm={24} md={7}>
            <RenderIcon
                imgPath={'/img/Icons/BusinessIcon1.png'}
                goto={'/business/energy'}
                title={'ธุรกิจพลังงาน'}
                imageWidth={imageWidth}
            />
        </Col>
        <RenderVR />
        <Col xs={24} sm={24} md={7}>
            <RenderIcon
                imgPath={'/img/Icons/BusinessIcon2.png'}
                goto={'/business/smart-grid'}
                title={'Smart Grid'}
                imageWidth={imageWidth}

            />
        </Col>
        <RenderVR />
        <Col xs={24} sm={24} md={7} >
            <RenderIcon
                imgPath={'/img/Icons/BusinessIcon3.png'}
                goto={'/business/automation'}
                title={'Automation and smart solution'}
                imageWidth={imageWidth}
            />
        </Col>
    </Row>)
}

const RenderIcon = ({ title, imgPath, goto, imageWidth = '56%' }) => {
    const navigate = useNavigate()
    return (
        <div className='icon-hover2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }} onClick={() => {
            navigate(goto)
        }}>
            <img src={imgPath} style={{ width: imageWidth, }} loading={'lazy'} alt='' />
            <h2
                className='on-hover'
                style={{ fontSize: '70%' ,textAlign:'center'}}
            >
                {title}
            </h2>
        </div>
    )
}

export default OurBusiness;
import React, { useEffect, useState } from 'react';
import { fetch } from '../../fetch'
import { Col, Row } from 'antd';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';

const Articles = ({ type = 'show' }) => {
    const [articleList, setArticleList] = useState([])
    const { t } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (t) {
            loadArticle('all')
        } else {
            loadArticle(type)
        }
    }, [])

    const loadArticle = async (val) => {
        const res = await fetch('POST', '/articles/get_articles.php', {
            show: val
        })
        setArticleList(res.items)
    }
    return (
        <>
            <div
                style={{
                    width: '100%',
                    maxWidth: '1200px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
            >
                <span
                    className='c-gold f-medium fs-36'
                >
                    บทความ
                </span>
                <Row justify={'center'} gutter={[30, 15]} className={'mt-3 mr-auto ml-auto'}  >
                    {
                        articleList.map(item => (
                            <Col
                                xs={24} sm={12} lg={6}
                                key={item.article_id}
                            >
                                <a
                                    href={`http://www.megawattenergys.com/article/${item.article_id}/${item.article_title}`}
                                    target='_blank' rel="noreferrer"
                                >
                                <div
                                    style={{
                                        border: '1px solid #cccc',
                                        aspectRatio: '1/1',
                                        // padding: '10px',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        color:'black'
                                        
                                    }}
                                    className={'art-hover'}
                                    // onClick={() => {
                                    //     window.open(`/article/${item.article_id}/${item.article_title}`)
                                    // }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            height: '60%'
                                        }}
                                        className="art-1"
                                    >
                                        <img
                                            width={'100%'}
                                            src={'/img/images/' + item.title_image_path}
                                            alt={''}
                                        // style={{maxHeight:'60%'}}
                                        />
                                    </div>
                                    <div style={{
                                        textAlign: 'left',
                                        padding: '5px 10px',
                                        backgroundColor: '#fff',
                                        // height:'100%'
                                        // transition:'0.3s'
                                    }}
                                        className="art-2"
                                    >
                                        <div style={{
                                            position: 'absolute',
                                            top: '-21px',
                                            left: '0px',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                        }}
                                            className={'bg-gold c-white fs-14'}
                                        >
                                            {moment(item.available_date).format('DD MMMM YYYY')}
                                        </div>
                                        <span className='font-medium'>{item.article_title}</span>
                                        <div className='art-intro' >
                                            {item.article_intro}
                                        </div>
                                    </div>
                                    <div className='icon-hover' style={{ position: 'absolute', bottom: '0px', right: '5px' }}>
                                        อ่านต่อ
                                    </div>
                                    </div>
                                </a>
                            </Col>
                        )
                        )
                    }
                </Row>
                {t !== 'all' && <div style={{ textAlign: 'right', marginTop: '10px' }}>
                    <a href='http://www.megawattenergys.com/article/all' style={{textDecoration:'none', color:'black'}} target='_blank' rel="noreferrer">
                        <span className='art-all'
                            // onClick={() => {
                            //     window.open('/article/all')
                            // }}
                            >บทความทั้งหมด</span>
                    </a>
                </div>}
            </div>
            {/* <Row> */}
        </>
    );
};

export default Articles;
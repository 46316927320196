// import logo from './logo.svg';
// import './App.css';
import React, { useEffect, useState, useContext, createContext, Suspense, lazy } from "react";
import Header1 from "./layout/Header";
import Home from "./views/Home/Home";
import Footer from "./layout/Footer";
import AboutUs from "./views/AboutUs/AboutUs";
// import './Fonts/metropolis/Metropolis-Bold.otf'
import './Fonts/PromptFont.css'
import './Fonts/MetropolisFont.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {
  OurBusiness,
  Energy,
  SmartGrid,
  Automation,
  Solacell,
  Biomass,
  Windpower
} from './views/OurBusiness/OurBusinessIndex'
import { useSelector, useDispatch } from 'react-redux'
import { Footer as AntFooter } from "antd/lib/layout/layout";
import { Helmet } from "react-helmet";
import { Project2 } from "./views/MyProject/Project";
import ProjectHome from "./views/MyProject/ProjectHome";
import ProjectBusiness from "./views/MyProject/ProjectBusiness";
import ContactUs from "./views/ContractUs/ContractUs";
import ArticleDetail from "./views/Articles/ArticleDetail";
import Articles from "./views/Articles/Articles";
import ProjectAll from "./views/MyProject/ProjectAll";
// export const LoadingContext = createContext();

function App() {
  const { lenguage } = useSelector(state => state)
  const dispatch = useDispatch()
  const [fontFamily, setFontfamily] = useState('')
  const [visibleHD, setVisibleHD] = useState(false)
  // const { isLoadingPage } = useSelector(state => state)

  useEffect(() => {
    switch (lenguage) {
      case 'TH': setFontfamily('font-th')
        break;
      case 'EN': setFontfamily('font-en')
        break;
      default:
    }
  }, [lenguage])

  function switchLenguage() {
    if (lenguage === 'TH') {
      dispatch({ type: 'set', lenguage: 'EN' })
    } else {
      dispatch({ type: 'set', lenguage: 'TH' })
    }
  }

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextMenu)
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])


  return (
    <div className={`d-body ${fontFamily}`}>
      {/* <Suspense  /> */}
      <Helmet>
        <title>MEGAWATT ENERGY SOLUTION</title>
        <meta name="description" content="MEGAWATT ENERGY SOLUTION" />
        <meta name="keywords" content="Megawatt energy ,บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด" />
      </Helmet>
      < Router basename="/" >
        <Header1 switchLenguage={switchLenguage} visible={{ get: visibleHD, set: setVisibleHD }} />
        <Routes >
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/business" element={<OurBusiness />} />
          <Route path="/business/energy" element={<Energy />} />
          <Route path="/business/energy/solafarm" element={<Solacell />} />
          <Route path="/business/energy/biomass" element={<Biomass />} />
          <Route path="/business/energy/windpower" element={<Windpower />} />

          <Route path="/business/smart-grid" element={<SmartGrid />} />
          <Route path="/business/automation" element={<Automation />} />

          <Route path="/project" element={<Project2 />} />
          <Route path="/project/home" element={<ProjectHome />} />
          <Route path="/project/business" element={<ProjectBusiness />} />
          <Route path="/project/home/all" element={<ProjectAll />} />
          <Route path="/project/business/all" element={<ProjectAll />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/article/:t" element={<Articles />} />
          <Route path="/article/:id/:name" element={<ArticleDetail />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <AntFooter style={{ backgroundColor: '#d1a748', marginTop: 'auto', padding: 0 }}>
          <Footer />
        </AntFooter>
      </Router>
    </div >
  );
}


export default App;

import { createStore } from 'redux'
const initialState = {
    lenguage: 'TH',
    isLoadingPage: true,
    isExpanHeader: false

}

const changeState = (state = initialState, { type, ...rest }) => {
    // console.log(state)
    // console.log(rest)
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store
class HeaderMenu {
    constructor(name, path) {
        this.name = name;
        this.path = path;
    }
}

export const text = {
    TH: {
        headerMenu: [
            new HeaderMenu('เกี่ยวกับเรา', '/about-us'),
            new HeaderMenu('ธุรกิจของเรา', '/business'),
            new HeaderMenu('โครงการของเรา', '/project'),
            new HeaderMenu('ติดต่อเรา', '/contact-us'),
        ]
    },
    EN: {
        headerMenu: [
            new HeaderMenu('ABOUT US', '/about-us'),
            new HeaderMenu('BUSINESS', '/packages'),
            new HeaderMenu('PROJECR', '/project'),
            new HeaderMenu('CONTACT US', '/contact-us'),
        ]
    }
}
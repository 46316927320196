import React from 'react';
import {
    Row,
    Col,
} from 'antd'
import '../ourbusiness.css'
const OverView = ({ imagePath = '', finishMW = '-', inProgress = '-', }) => {
    return (
        <div className='div-bg mt-3' style={{ width: '100%', padding: '20px' }}>
            <Row >
                <Col sm={24} md={12}>
                    <div className='fs-24' style={{ marginLeft: '10%' }} >
                        ภาพรวมในการดำเนินงาน
                    </div>
                    <br />
                    <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ marginRight: 'auto' }}>
                            ดำเนินการแล้วเสร็จ
                        </div>
                        <div>
                            {finishMW} MW
                        </div>
                    </div>
                    <br />
                    <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ marginRight: 'auto' }}>
                            อยู่ระหว่างการก่อสร้าง
                        </div>
                        <div>
                            {inProgress} MW
                        </div>
                    </div>
                </Col>
                <Col sm={24} md={12}>
                    <img src={imagePath} alt='' style={{ width: '100%' }} loading="lazy" />
                </Col>
            </Row>
        </div>
    );
};

const OverView2 = ({ imagePath = '', finishPJ = '-', inProgress = '-', }) => {
    return (
        <div className='div-bg mt-3' style={{ width: '100%', padding: '20px' }}>
            <Row >
                <Col sm={24} md={12}>
                    <div className='fs-24' style={{ marginLeft: '10%' }} >
                        ภาพรวมในการดำเนินงาน
                    </div>
                    <br />
                    <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ marginRight: 'auto' }}>
                            ดำเนินการแล้วเสร็จ
                        </div>
                        <div>
                            {finishPJ} โครงการ
                        </div>
                    </div>
                    <br />
                    <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ marginRight: 'auto' }}>
                            อยู่ระหว่างการก่อสร้าง
                        </div>
                        <div>
                            {inProgress} โครงการ
                        </div>
                    </div>
                </Col>
                <Col sm={24} md={12}>
                    <img src={imagePath} alt='' style={{ width: '100%' }} loading="lazy" />
                </Col>
            </Row>
        </div>
    );
};

export { OverView2 }
export default OverView;
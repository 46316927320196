import React, { useEffect } from 'react';
import { Row, Col } from 'antd'
import { Helmet } from 'react-helmet';
import { OverView2 } from './Energies/Components';
import { RenderBusiness } from './OurBusiness';
const Automation = () => {

    return (
        <>
            <Helmet>
                <title>AUTOMATION AND SMART SOLUTION - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'หุ่นยนต์ทำความสะอาดแผงโซล่าเซลล์'}
                />
            </Helmet>
            <div
                style={{
                    margin: '20px auto',
                    width: '80%',

                }}
                className={'font-medium'}
            >
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'center' }}>
                        <img
                            src='/img/Icons/BusinessIcon2.png'
                            style={{ width: '70%', }} loading={'lazy'} alt='' />
                    </Col>
                    <Col span={18}>
                        <h1
                            className='c-gold fs-30'
                        >
                            Automation and smart solutions
                        </h1>
                        <div className='c-gray fs-18 font-light'>
                            เราดำเนินการออกแบบ,ติดตั้ง และบำรุงรักษา รวมถึงให้คำปรึกษาด้าน ระบบอัตโนมัติในภาคอุตสาหกรรมหลายส่วน เช่น เครื่องทำความสะอาดแผงอัตโนมัติ (Auto PV Cleaning) ระบบสายพานลำเลียง (Conveyor) และแขนกล (Industrial Robot) เป็นต้น
                        </div>
                    </Col>
                </Row>
                <br />
                <OverView2
                    imagePath='/img/OurBusiness/automation.jpeg'
                />

                {/* <div className='fs-24'>
                    ภาพรวมในการดำเนินงาน
                </div> */}
                {/* <div style={{ backgroundColor: '#c6c6c6', height: '500px' }}>
                    Demo
                </div> */}
                {/* <img src='/img/Live-Demo.jpg' style={{ width: '100%' }} /> */}
                <br />
                <RenderBusiness imageWidth='30%' />
            </div>
        </>
    );
};

export default Automation;
import React, { useEffect } from 'react';
import {
    Row,
    Col,
} from 'antd'
import { Helmet } from 'react-helmet';
import { OverView2 } from './Energies/Components';
import { RenderBusiness } from './OurBusiness';

const SmartGrid = () => {

    return (
        <>
            <Helmet>
                <title>SMART GRID - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'โครงการนำร่องการพัฒนาระบบ SMART GRID โครงข่าย มหาวิทยาลัยราชภัฏกำแพงเพชร จังหวัด กำแพงเพชร'}
                />
            </Helmet>

            <div
                style={{
                    margin: '20px auto',
                    width: '80%',

                }}
                className={'font-medium'}
            >
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'center' }}>
                        <img
                            src='/img/Icons/BusinessIcon2.png'
                            style={{ width: '70%', }} loading={'lazy'} />
                    </Col>
                    <Col span={18}>
                        <h1
                            className='c-gold fs-30'
                        >
                            ธุรกิจระบบโครงข่ายไฟฟ้าอัจฉริยะ (Smart Grid Business)
                        </h1>
                        <div className='c-gray fs-18 font-light' style={{ textAlign: 'justify' }}>
                            เราดำเนินการออกแบบ,ติดตั้ง และบำรุงรักษา รวมถึงให้คำปรึกษาด้านระบบโครงข่ายไฟฟ้าอัจฉริยะ (Smart Grid) สมาร์ทกริด คือ ระบบโครงข่ายไฟฟ้าอัจฉริยะ ที่นำเทคโนโลยีมาผสมผสาน เพื่อให้ครอบคลุมทั้งระบบไฟฟ้า
                            <br />
                            ตั้งแต่การผลิตไฟฟ้า การส่งไฟฟ้า การจำหน่ายไฟฟ้า ไปจนถึงผู้บริโภค การเก็บข้อมูลและการตัดสินใจของระบบควบคุมอัตโนมัติของระบบโครงข่ายเพื่อทำการปรับปรุงประสิทธิภาพความเชื่อถือได้ และรองรับการนำรถยนต์ไฟฟ้าเข้ามาใช้งานด้วย
                        </div>

                    </Col>
                </Row>
                <br />
                <OverView2
                    imagePath='/img/OurBusiness/environmental.jpg'
                    finishPJ='2'
                />
                {/* <div className='fs-24 mt-3'>
                    ภาพรวมในการดำเนินงาน
                </div> */}
                {/* <div style={{ backgroundColor: '#c6c6c6', height: '500px' }}>
                    Demo
                </div> */}
                {/* <img src='/img/Live-Demo.jpg' style={{ width: '100%' }} /> */}
                <br />
                {/* <div className='fs-24 c-gold mt-5'>
                    Smart Grid ตอบสนองวิถีชีวิตคนไทยในเมืองอย่างไร
                </div>
                <div className='fs-18 c-gray font-light mt-2'
                    style={{ display: 'block', lineHeight: '28px' }}
                >
                    สมาร์ทกริด (Smart Grid) คือระบบโครงข่ายไฟฟ้าอัจฉริยะ ที่นำเทคโนโลยีหลายประเภทเข้ามาทำงานร่วมกัน โดยครอบคลุมตั้งแต่การประยุกต์ใช้งานเทคโนโลยีเหล่านั้น ตลอดทั้งห่วงโซ่ของระบบไฟฟ้ารตั้งแต่การผลิตไฟฟ้า การส่งไฟฟ้า การจำหน่ายไฟฟ้า ไปจนถึงภาคส่วนของผู้บริโภคได้อย่างชาญฉลาด
                    การสื่อสารในการเก็บข้อมูลและทำการสั่งการควบคุมโครงข่ายไฟฟ้าโดยใช้ข้อมูลดังกล่าวในการตัดสินใจ เช่น เก็บข้อมูลพฤติกรรมการใช้ไฟฟ้าจากผู้ใช้งานและการผลิตไฟฟ้าจากผู้ผลิต การควบคุมอัตโนมัติของระบบโครงข่ายไฟฟ้าเพื่อทำการปรับปรุงประสิทธิภาพความเชื่อถือได้ ความค้มค่าทางเศรษฐศาสตร์
                    และความยั่งยืนในการผลิตและจ่ายไฟฟ้าในระบบโครงข่ายไฟฟ้า นั่นคือผู้ใช้ไฟฟ้าทั่วไฟตั้งแต่ภาคบ้านเรือน ภาคอุตสาหกรรม ภาคธุรกิจและการพาณิชย์ เป็นต้น นอกจากนี้ ยังครอบคลุมไปถึงการเชื่อมต่อพลังงานไฟฟ้าหมุนเวียนเข้าระบบโครงข่ายไฟฟ้าและเตรียมพร้อมรองรับการนำรถยนต์ไฟฟ้าเข้ามาใช้งานในอนาคต เป็นต้น
                </div> */}
                < RenderBusiness imageWidth='30%' />
            </div>
        </>
    );
};

export default SmartGrid;
import React, { useState, useRef, useEffect } from 'react';
import {
    CaretRightOutlined
} from '@ant-design/icons'
import '../css/figurEffectLily.css'
import { useNavigate } from 'react-router-dom';
const UtilCard2 = ({ imgPath, title, goto }) => {

    const [isVisible, setVisible] = useState(true)
    const domRef = useRef()
    const navigate = useNavigate()
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting))
        })
        observer.observe(domRef.current);
        // return () => {
        //     if (domRef.current !== undefined) {
        //         observer.unobserve(domRef.current)
        //     }
        // }
    }, [])
    return (
        // <div
        //     className={`card-custom2 fade-in-section ${isVisible ? 'is-visible' : ''}`}
        //     style={{
        //         marginTop: '10px',
        //         marginBottom: '10px',
        //         position: 'relative'
        //         // minHeight: '500px',
        //     }}
        //     ref={domRef}
        // >
        //     <img src={imgPath} style={{ width: '100%', position: 'absolute', zIndex: -90 }} />
        //     <div
        //     >
        //         {title}
        //     </div>
        //     <span className='icon-hover act-more' style={{ color: '#999', fontSize: '20px' }}>
        //         More<CaretRightOutlined style={{ display: 'inline-grid' }} />
        //     </span>
        // </div>
        <figure className='effect-lily'>
            <div
                // className={`card-custom fade-in-section ${isVisible ? 'is-visible' : ''}`}
                className={`card-custom2`}
                style={{
                    textAlign: 'center',
                    overflow: "hidden",
                    // borderRadius: '10px',
                    maxWidth: '600px',
                    margin:'0 auto'
                }}
                onClick={() => navigate(goto)}
                ref={domRef}
            >
                <img src={imgPath} alt="" loading={'lazy'}/>
                {/* <img src={imgPath} style={{ width: "100%", display: 'flex', justifyContent: 'start' }} alt="" /> */}
                <figcaption style={{ position: 'absolute', width: '100%', top: '20%' }}>
                    <div style={{ textAlign: 'left' }}>
                        <span style={{
                            // fontFamily: 'Metropolis',
                            display: 'block',
                            color: '#fff',
                            fontSize: '30px',
                            lineHeight: '33px',
                        }}>{title}</span>
                        <span className='icon-hover act-more' style={{ color: '#fff', fontSize: '20px' }}>
                            More<CaretRightOutlined style={{ display: 'inline-grid' }} />
                        </span>
                    </div>
                </figcaption>
            </div>
        </figure>
    );
};

export default UtilCard2;
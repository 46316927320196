import Axios from 'axios'

const BASE_URL = 'https://www.api.megawattenergys.com/api'
const fetch = async (method = 'GET', path, params = {}) => {
    try {
        const { data } = await Axios({
            method: method,
            url: BASE_URL + path,
            data: params,
            headers: {
                "Content-Type": 'application/json'
            },
        })
        // console.log('checkFetch:', data)
        return data
    } catch (err) {
        return {
            status: false,
        }
    }
}


export { fetch }

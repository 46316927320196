import React, { Suspense, useEffect } from 'react';
import {
    Row,
    Col,
    Carousel,
    Spin
} from 'antd'
import UtilCard from '../UtilCard';
import { Helmet } from 'react-helmet';
import { Projects } from '../MyProject/Project';
import { useNavigate } from 'react-router-dom';
import Articles from '../Articles/Articles';
const Home = () => {
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'เป็นผู้นำด้านธรกิจพลังงานทดแทนแบบครบวงจร และธุรกิจระบบไฟฟ้า ด้วยเทคโนโลยีชั้นนำเพื่อพัฒนาโครงสร้างพื้นฐานอย่างยั่งยืน'}
                />
            </Helmet>
            {/* <Suspense 
                fallback={<div>Loading</div>}
            > */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                <video
                    style={{ maxHeight: '90vh', width: '100vw' }}
                    muted
                    autoPlay="autoplay"
                    loop
                    playsInline
                    preload={'true'}
                    // controlsList={'noDownload'}
                    onContextMenu={() => false}
                    // controls
                    disablePictureInPicture={true}
                    id="myVideo" src="/Videos/home-video.mp4" type="video/mp4"
                />
            </div>
            {/* <Carousel autoplay style={{
                // height: '700px',
                // maxWidth: '1920px',
                // maxHeight: '700px',
                // minHeight: '400px'
            }}>
                {/* <img
                    src='/img/Banner/Banner_1.jpg'
                    style={{
                        width: '100%',
                        height: '100%',
                        // minHeight: '400px',
                        objectFit:'fill'
                    }}
                    loading="lazy"
                /> 

                <img style={{
                    width: '100%',
                    height: '100%'
                }} /> 
            </Carousel> */}
            <br />
            <div
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
                <div className='text-center font-medium' style={{
                    fontSize: '26px',
                    color: '#6d6e71',
                    marginTop: '20px',
                    width: '90%',
                }}>
                    ผู้ให้บริการด้านธุรกิจพลังงานทดแทนแบบครบวงจร
                    <br />
                    ด้วยนวัตกรรมใหม่ เพื่อพัฒนาโครงสร้างพื้นฐานอย่างยั่งยืน
                    <div>
                        <span className='c-gold' style={{ fontFamily: 'Metropolis', fontSize: '36px', fontWeight: 'bold' }}>Energy for Life</span>
                    </div>
                    <span className='c-gold' style={{ fontFamily: 'Metropolis' }}>&#8246;<span className='c-gray font-medium' style={{ fontFamily: 'Prompt' }}>พลังงานเพื่อชีวิต</span >&Prime;</span>
                    <br />
                    <span className='fs-18 icon-hover readmore-icon'
                        style={{
                            backgroundColor: '#d1a748', color: '#fff', paddingLeft: '10px', paddingRight: '10px',
                            borderRadius: '8px'
                        }}
                        onClick={() => navigate('/about-us')}
                    >
                        Read More
                    </span>
                    <br />
                    <br />
                    <div className='mt-3 mb-4' >
                        <div style={{overflow:'hidden' , width:'100%' , maxWidth:'1200px' , marginLeft:'auto' , marginRight:'auto' }}>
                        <a className='img-hover' href='http://m.me/169579936851938' target='_blank' rel="noreferrer">
                            <img  src='/img/Banner_Promotion.jpg' alt='megawatt promotion' width={'100%'} style={{ maxWidth: '1200px' }} loading="lazy" />
                        </a>
                        </div>
                    </div>
                    <div className='mt-3 mb-4'>
                        <img src='/img/Icon-01.png' alt='' width={'100%'} style={{ maxWidth: '1200px' }} loading="lazy" />
                    </div>
                    <span
                        className='c-gold fs-36'
                    >
                        ธุรกิจของเรา
                    </span>
                    <br />
                    <span
                        className='c-gray'
                        style={{
                            fontSize: '16px',
                            display: 'block',
                            lineHeight: '28px'
                        }}
                    >
                        โครงสร้างธุรกิจหลักของเมกะวัตต์ แบ่งออกเป็น 3 กลุ่ม ได้แก่ ธุรกิจพลังงาน (Energy Business)
                        <br />
                        ธุรกิจระบบโครงข่ายไฟฟ้าอัจฉริยะ (Smart Grid Business) ธุรกิจระบบอัตโนมัติ (Automation Business)
                    </span>
                    <br />
                    <Row justify={'center'}
                        style={{
                            width: '100%',
                            maxWidth: '1200px',
                            margin: '0 auto',
                        }}>
                        <Col xs={24} md={8}>
                            <UtilCard goto='/business/energy' imgPath='/img/Energy-Business.jpg' title={'ธุรกิจพลังงาน'} />
                        </Col>
                        <Col xs={24} md={8}>
                            <UtilCard goto='/business/smart-grid' imgPath={'/img/Smart-Grid.jpg'} title={'Smart Grid'} />
                        </Col>
                        <Col xs={24} md={8}>
                            <UtilCard goto='/business/automation' imgPath={'/img/Automation.jpg'} title={'Automation and smart solution'} />
                        </Col>
                    </Row>
                    <br />
                    <div className='mb-3'>
                        <span
                            className='c-gold fs-36'
                        >
                            โครงการของเรา
                        </span>
                    </div>
                    <Projects />
                </div>
                <br />
                <img src='/img/Live-Demo.jpg' alt='' className='icon-hover' style={{ width: '100%', margin: '25px auto' }} loading="lazy" />
                <br />

                <Articles />

            </div>
            {/* </Suspense> */}
        </>
    );
};

export default Home;
import React from 'react';
import {
    Col,
    Row,
} from 'antd'
import UtilCard2 from '../UtilCard2';
import { Helmet } from 'react-helmet';

const Project = ({ }) => {
    return (
        <div style={{
            width: '90%',
            margin: '0 auto',
            textAlign: 'center'
        }}>
            <span className='fs-36 c-gold font-medium ' >
                PROJECTS ME
            </span>
            <br />
            <br />
            <Projects />
            <br />
            <br />
            <img src='/img/Icon-02.png' style={{ width: '100%' }} loading={'lazy'} alt='' />
        </div>
    );
};

export default Project;

export const Project2 = () =>{
    return (
        <>
           <Helmet>
                <title>PROJECTS ME - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'Projects me'}
                    content={'โครงการของเรา'}
                />
            </Helmet>
            <Project/>
        </>
    )
}


export const Projects = ({ }) => {
    return (
        <>
         
            <Row justify={'space-between'}  style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                <Col xs={24} md={11}>
                    <UtilCard2
                        imgPath={'/img/Solar-Home.jpg'}
                        title={(
                            <div style={{
                                display: 'block',
                                lineHeight: '25px'
                            }}>
                                <span style={{ color: 'white', fontSize: '26px' }}>โซล่าร์เซลล์เพื่อ 'บ้าน'</span>
                                <br />
                                <span className='font-light' style={{ color: 'white', fontSize: '18px' }} >ผลิตไฟใช้เอง ประหยัดตั้งแต่ยูนิตแรก</span>
                            </div>
                        )}
                        goto={'/project/home'}
                    />
                </Col>
                <Col xs={24} md={11}>
                    <UtilCard2
                        imgPath={'/img/Solar-Business.jpg'}
                        title={(
                            <div style={{
                                display: 'block',
                                lineHeight: '25px'
                            }}>
                                <span style={{ color: 'white', fontSize: '26px' }}>โซล่าร์เซลล์เพื่อ 'ธุรกิจ'</span>
                                <br />
                                <span className='font-light' style={{ color: 'white', fontSize: '18px' }} >ทั้งภาครัฐและเอกชน</span>
                            </div>
                        )}
                        goto={'/project/business'}

                    />
                </Col>
            </Row>
        </>
    )
}
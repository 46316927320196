import { Row, Col } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate()

    const handleCkick = (path) => {

    }

    return (
        <div
            style={{
                width: '90%',
                // backgroundColor:'black',
                // height: '30px',
                margin: '40px auto',
                color: '#fff',
                paddingTop: '35px',
                fontSize: '25px',
                fontWeight: '500'
            }}
        >
            <Row justify={'center'} style={{ marginBottom: '40px' }}>
                <Col xs={24} sm={24} md={12}>
                    <div
                        style={{
                            fontSize: '25px',
                            fontWeight: '600',
                            marginBottom: '10px'
                        }}
                    >
                        บริษัท เมกะวัตต์ เอ็นเนอร์จี โซลูชั่น จำกัด
                    </div>
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            marginBottom: '10px'

                        }}
                    >
                        20/15 ม.7 ต.คลองสวนพลู
                        <br />
                        อ.พระนครศรีอยุธยา จ.พระนครศรีอยุธยา 13000
                    </div>
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: '500'
                        }}
                    >
                        โทร : +66 (0)35-955389
                        <br />
                        <Row>
                            <Col>
                                อีเมล์ :
                            </Col>
                            <Col>
                                <ul className='no-bullet' style={{ marginLeft: '10px' }}>
                                    {/* <li>
                                        thitiphan.t@megawattenergys.com
                                    </li>
                                    <li>
                                        megawattenergys@gmail.com
                                    </li> */}
                                    <li>
                                        info@megawattenergys.com
                                    </li>
                                    <li>
                                        megawattenergys@gmail.com
                                    </li> 
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={6} style={{
                    // paddingLeft: '20px'
                }}>
                    <div
                        style={{
                            fontSize: '25px',
                            fontWeight: '600',
                            marginBottom: '10px'
                        }}
                    >
                        Main Menu
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        <span
                            onClick={() => navigate('/about-us')}
                            className='icon-hover foot-text-hover'>
                            เกี่ยวกับ
                        </span>
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        <span
                            onClick={() => navigate('/business')}
                            className='icon-hover foot-text-hover'>
                            บริการของเรา
                        </span >
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        <span
                            onClick={() => navigate('/project')}
                            className='icon-hover foot-text-hover'>
                            โครงการของเรา
                        </span>
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        <span
                            onClick={() => navigate('/contact-us')}
                            className='icon-hover foot-text-hover'>
                            ติดต่อเรา
                        </span>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={6} >
                    <div style={{
                        width: '100%',
                        // textAlign: 'right'
                    }}>
                        <Row justify={'center'} >
                            <Col span={24} style={{
                                textAlign: 'center'
                            }} >
                                Social Media
                            </Col>
                            <Col span={4}
                                style={{
                                    fontSize: '25px',
                                    fontWeight: '600',
                                    marginBottom: '10px',
                                }}>
                                <a href='https://www.facebook.com/MegawattEnergys' target='_blank' rel="noreferrer">
                                    <img className='icon-hover' src='/img/Social/facebook.png'
                                        width={'60%'} loading={'lazy'}  alt=''/>
                                </a>
                            </Col>
                            <Col span={4}>
                                <a href='https://lin.ee/AaR6EDV' target='_blank' rel="noreferrer">
                                    <img className='icon-hover' src='/img/Social/line.png' width={'60%'} loading={'lazy'} alt=''/>
                                </a>
                            </Col>
                            <Col span={4}>
                                <a href='https://www.youtube.com/channel/UCk8UI-UZGrLZtAA09Zz894w' target="_blank" rel="noreferrer">
                                    <img src='/img/Social/youtube.png' width={'60%'} loading={'lazy'} alt=''/>
                                </a>
                            </Col>
                            <Col span={4}>
                                <a href='https://www.tiktok.com/@megawattenergy888?is_from_webapp=1&sender_device=pc' target='_blank' rel="noreferrer">
                                    <img src='/img/Social/tiktok.png' width={'60%'} loading={'lazy'} alt='' />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <hr style={{ width: '100%', borderTop: '2px solid', opacity: 1 }} />
            <div
                className='text-center'
                style={{
                    margin: '-10px',
                    paddingBottom: '10px',
                    // paddingTop: '-15px'
                }}
            >
                <span
                    style={{
                        fontSize: '14px'
                    }}
                >
                    COPYRIGHT BY MEGAWATT ENERGY SOLUTION CO.,LTD.
                </span>
            </div>

        </div>
    );
};

export default Footer;
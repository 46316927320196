import React, { useEffect, useState } from 'react';
import {
    Link,
} from 'react-router-dom'
import '../css/header.css'
import { text } from '../txts';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown, Row, } from 'antd';
import {
    DownOutlined,
    SearchOutlined,
    UnorderedListOutlined
} from '@ant-design/icons'
import { CCollapse, CContainer, CNav, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler, CNavItem, CNavLink } from '@coreui/react';



const Header1 = ({ switchLenguage = () => { }, }) => {
    const style1 = {
        backgroundColor: 'none',
        position: 'absolute',
        width: '100%'
    }
    const [navHeight, setNavHeight] = useState(70)
    const { lenguage, isExpanHeader } = useSelector(state => state)
    // const [visible, setVisible] = useState(false)
    const [navStyle, setnavStyle] = useState(style1)
    const navigate = useNavigate()
    const dispath = useDispatch()
    const location = useLocation()
    //isExpanHeader
    window.onscroll = () => {
        if (document.documentElement.scrollTop < -30 + navHeight) {
            setNavHeight(70)
        } else {
            setNavHeight(50)
        }
        // if(visible){
        //     setVisible(false)
        // }
    }
    useEffect(() => {
        dispath({ type: 'set', isExpanHeader: false })
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])




    const setShowHeader = (stat) => {
        dispath({ type: 'set', isExpanHeader: !stat })
    }


    const styles = {
        color: '#6d6e71',
        // paddingLeft: '10px',
        textDecoration: 'none',
        fontFamily: 'Prompt',
        fontSize: '1rem'
    }

    const items = [
        {
            key: '1',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={styles}
                    to={'/business/energy'}
                >
                    ธุรกิจพลังงาน
                </Link>
            ),
        },
        {
            key: '1-1',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={{ ...styles, paddingLeft: '10%' }}
                    to={'/business/energy/solafarm'}
                >
                    โรงไฟฟ้าพลังงานแสงอาทิตย์
                </Link>
            ),
        },
        {
            key: '1-2',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={{ ...styles, paddingLeft: '10%' }}
                    to={'/business/energy/biomass'}
                >
                    โครงการโรงไฟฟ้าชีวมวล
                </Link>
            ),
        },
        {
            key: '1-3',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={{ ...styles, paddingLeft: '10%' }}
                    to={'/business/energy/windpower'}
                >
                    โครงการโรงไฟฟ้าพลังงานลม
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={styles}
                    to={'/business/smart-grid'}
                >
                    Smart grid
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={styles}
                    to={'/business/automation'}
                >
                    Automation and smart solution
                </Link>
            ),
        },
    ];

    const projectItems = [
        {
            key: '1',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={styles}
                    to={'/project/home'}
                >
                    โซล่าร์เซลล์เพื่อ 'บ้าน'
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link
                    className='nav-hover font-light'
                    style={{ ...styles }}
                    to={'/project/business'}
                >
                    โซล่าร์เซลล์เพื่อ 'ธุรกิจ'
                </Link>
            ),
        },
    ]


    return (
        <CNavbar expand={'xl'} placement={'sticky-top'} style={{ backgroundColor: '#fff' }} className={navHeight < 70 ? 'nav-body' : ''}>
            <CContainer fluid>
                <CNavbarBrand
                    onClick={() => {
                        navigate('/home')
                    }}
                    className={'icon-hover'}
                    style={{ marginLeft: '5%' }}
                >
                    <div style={{
                        marginLeft: '5%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        <img className='img-brand' style={{
                            height: `${navHeight}px`
                        }} src='/img/Logo-01.png' loading={'lazy'} alt={''} />
                    </div>
                </CNavbarBrand>
                <CNavbarToggler
                    aria-label='Toggle navigation'
                    aria-expanded={isExpanHeader}
                    onClick={() => setShowHeader(isExpanHeader)}
                />
                <CCollapse className='navbar-collapse justify-content-end font-medium trans-height' visible={isExpanHeader}>
                    <CNavbarNav style={{ marginLeft: '5%', marginRight: '5%' }} className={(isExpanHeader ? 'nav-drop' : '') + ' fs-18'}>
                        {/* {text[lenguage].headerMenu.map((item, i) => (
                            <CNavItem key={i.toString()} >
                                {/* <CNavLink
                                    className='nav-hover '
                                    style={{
                                        color: '#6d6e71',
                                        paddingLeft: '10px',
                                        fontSize: '25px',
                                    }}
                                    key={item.name} href={item.path}
                                >
                                    {item.name}
                                </CNavLink> 
                                <Link
                                    className='nav-hover '
                                    style={{
                                        color: '#6d6e71',
                                        paddingLeft: '10px',
                                        fontSize: '25px',
                                        textDecoration: 'none'
                                    }} key={item.name} to={item.path}>{item.name}</Link>
                            </CNavItem>
                        ))
                        } */}
                        <CNavItem  >
                            <Link
                                className='nav-hover'
                                style={styles}
                                key={'home'}
                                to={'/home'}
                            >
                                หน้าหลัก
                            </Link>
                        </CNavItem>
                        <CNavItem  >
                            <Link
                                className='nav-hover'
                                style={styles}
                                key={'เกี่ยวกับเรา'}
                                to={'/about-us'}
                            >
                                เกี่ยวกับ
                            </Link>
                        </CNavItem>
                        <CNavItem >
                            {
                                isExpanHeader ? <Link
                                    className='nav-hover'
                                    style={styles}
                                    key={'ธุรกิจของเรา'}
                                    to={'/business'}
                                >
                                    ธุรกิจของเรา
                                </Link> :
                                    <Dropdown
                                        overlayClassName='cus-dropdown-deader'
                                        menu={
                                            {
                                                items,
                                            }
                                        }
                                        placement="bottomLeft"
                                        trigger={['hover']}
                                    >
                                        <Link
                                            className='nav-hover'
                                            style={styles}
                                            key={'ธุรกิจของเรา'}
                                            to={'/business'}
                                        >
                                            ธุรกิจของเรา
                                        </Link>
                                    </Dropdown>
                            }

                        </CNavItem>
                        <CNavItem >
                            {isExpanHeader ? <Link
                                className='nav-hover'
                                style={styles}
                                key={'โครงการของเรา'}
                                to={'/project'}
                            >
                                โครงการของเรา
                            </Link> : <Dropdown
                                overlayClassName='cus-dropdown-deader'
                                menu={
                                    {
                                        items: projectItems
                                    }
                                }
                                placement="bottomLeft"
                                trigger={['hover']}

                            >
                                <Link
                                    className='nav-hover'
                                    style={styles}
                                    key={'โครงการของเรา'}
                                    to={'/project'}
                                >
                                    โครงการของเรา
                                </Link>
                            </Dropdown>
                            }
                        </CNavItem>
                        <CNavItem >
                            <Link
                                className='nav-hover'
                                style={styles}
                                key={'ติดต่อเรา'}
                                to={'/contact-us'}
                            >
                                ติดต่อเรา
                            </Link>
                        </CNavItem>
                        <CNavItem >
                            <div style={{
                                ...styles,
                                color: '#6d6e71',
                                textAlign: 'left',
                                height: '100%',
                                right: '0px',
                                // display: 'flex',
                                // flexDirection: 'row',
                                // marginLeft: '5%'
                                // justifyContent:'flex-end'
                            }}
                                className={'menu-icon-respon'}
                            >
                                <SearchOutlined style={{ paddingRight: '10px', paddingLeft: '10px', display: 'inline-grid' }} />
                                <span
                                    className='icon-hover menu-icon-respon'
                                    style={{
                                        // width: 150,
                                        // alignSelf: 'end',
                                        color: '#6d6e71',
                                        borderLeft: '1px solid ',
                                        paddingLeft: '10px'
                                    }}
                                    onClick={switchLenguage}
                                >
                                    {lenguage}
                                </span>
                            </div>
                        </CNavItem>
                    </CNavbarNav>
                </CCollapse>
            </CContainer >
        </CNavbar >
    );
};

export default Header1;
import React, { useEffect } from 'react';
import {
    Row,
    Col,
} from 'antd'
import { Helmet } from 'react-helmet';
import OverView from './Components';
import './biomass.css'
import { RenderBusiness } from '../OurBusiness';

const Biomass = () => {

    return (
        <>
            <Helmet>
                <title>BIOMASS - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'เมกะวัตต์เป็นผู้ดำเนินโครงการโรงไฟฟ้า SPP ชีวมวลที่ใช้เศษไม้เป็นเชื้อเพลิง 1 โครงการในประเทศ มีกำลังการผลิตไฟฟ้าติดตั้ง 25 เมกะวัตต์'}
                />
            </Helmet>
            <div
                style={{
                    margin: '20px auto',
                    width: '80%',
                }}
                className={'font-medium'}
            >
                <Row align={'middle'} >
                    <Col xs={24} sm={24} md={6} style={{
                        textAlign: 'center'
                    }}>
                        <img src='/img/Icons/Icon-Energy-02.png' alt='' style={{ width: '60%', maxWidth: '150px' }} loading={'lazy'} />
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                        <h1
                            className='c-gold fs-30'
                        >
                            โครงการโรงไฟฟ้าชีวมวล
                        </h1>
                        <div className='c-gray fs-18' style={{ textAlign: 'justify' }}>
                            เราดำเนินการออกแบบ,ติดตั้ง และบำรุงรักษา รวมถึงให้คำปรึกษาด้านโรงไฟฟ้าชีวมวลโรงไฟฟ้าชีวมวล คือ โรงไฟฟ้าที่ใช้เศษวัสดุต่างๆที่เป็นชีวมวล เป็นเชื้อเพลิงในการผลิตไฟฟ้า หรือ ผลิตไอน้ำ ซึ่งอาจเป็นวัสดุชนิดเดียวกันหรือหลายชนิดรวมกัน เช่น โรงน้ำตาลใช้กากอ้อยที่ได้จากการหีบอ้อยเป็นเชื้อเพลิงในการผลิตไฟฟ้าโรงสีขนาดใหญ่ที่ใช้แกลบเป็นเชื้อเพลิงหลักในการผลิตไฟฟ้าการใช้ก๊าซชีวภาพ(Biogas) จากการหมักน้ำเสีย(ที่ได้มาจากกระบวนการผลิตทางอุตสาหกรรมหรือมูลสัตว์(จากฟาร์มเลี้ยงสัตว์) มาผลิตกระแสไฟฟ้า ฯลฯ
                        </div>
                    </Col>
                </Row>
                <br />
                <OverView
                    imagePath='/img/OurBusiness/biomass.jpg'
                />
                <br />
                <div className='fs-24 c-gold '>
                    กระบวนการผลิตไฟฟ้าจากชีวมวล
                    <ul className='fs-20  c-gray li-2c'>
                        <li>งานฐานราก (Civil Work)</li>
                        <li>หม้อไอน้ำ (Boiler)</li>
                        <li>ระบบลำเลียงเชื้อเพลิง (Fuel handing System)</li>
                        <li>ระบบลำเลียงขี้เถ้า (Ash handing System)</li>
                        <li>เครื่องกำเนิดไฟฟ้า (Steam Turbine and Generator)</li>
                        <li>ระบบน้ำ (Water Treatment System)</li>
                        <li>ระบบควบคุม (Control and Instrument)</li>
                        <li>ระบบเชื่อมต่อการไฟฟ้า (Transmission Line)</li>
                    </ul>
                </div>
                <div className='fs-24 c-gold'>
                    กระบวนการผลิตไฟฟ้าจากชีวมวล
                </div>
                <div className='fs-18 font-light c-gray'>
                    <ol >
                        <li>น้ำดิบจากแหล่งน้ำที่เตรียมไว้ นำไปผ่านกระบวนการกรองเพื่อเป็นน้ำประปาใช้ภายในโรงงาน, ในระบบหล่อเย็น และนำไปขจัดแร่ธาตุเพื่อส่งผ่านไปใช้ยังเครื่องผลิตไอน้</li>
                        <li>เชื้อเพลิงชีวมวลจะถูกลำเลียงจากลานกองด้วยสายพานต่อเนื่องเข้าสู่ห้องเผาไหม้เพื่อให้ความร้อนกับหม้อไอน้ำ (Boiler) เพื่อผลิตไอน้ำ</li>
                        <li>ไอน้ำแรงดันสูงที่ได้จะส่งต่อไปหมุนกังหันไอน้ำ (Turbines) ซึ่งต่ออยู่กับเครื่องกำเนิดไฟฟ้า (Generator) เพื่อผลิตกระแสไฟฟ้า</li>
                        <li>ไอน้ำที่ผ่านเครื่องกังหันไอน้ำแล้วยังคงมีความร้อนเหลืออยู่จะถูกนำไปผ่านเครื่อง ควบแน่น (Condenser) เพื่อเปลี่ยนเป็นน้ำแล้วนำกลับเติมหม้อไอน้ำ (Boiler) เพื่อผลิตไอน้ำไปใช้ในระบบอีกครั้ง ส่วนน้ำหล่อเย็นที่รับความร้อนมาจากเครื่องควบแน่น (Condenser) จะถูกส่งไปยังหอหล่อเย็น (Cooling Tower) เพื่อระบายความร้อนและนำกลับมาหมุนเวียนเพื่อเป็นน้ำหล่อเย็นในระบบอีกครั้ง</li>
                        <li>ไอร้อนและขี้เถ้าลอยจะถูกนำไปผ่าน เครื่องดักจับฝุ่นแบบไฟฟ้าสถิตแรงสูง (ESP) เพื่อดักจับฝุ่นก่อนปล่อยออกสู่ภายนอก</li>
                        <li>ขี้เถ้าที่ถูกเก็บกักไว้ในเครื่องดักจะถูกลำเลียงบรรจุลงรถขนส่งขี้เถ้าหรือ บรรจุถุงที่คุณภาพแข็งแรงและปิดมิดชิดเพื่อนำส่งลูกค้า เช่น อุตสาหกรรมเกษตรใช้เป็นวัสดุปรับคุณภาพดินอุตสาหกรรมซีเมนต์ และอุตสาหกรรมก่อสร้าง เป็นต้น</li>
                    </ol>
                </div>
                <br />
                <RenderBusiness imageWidth='30%' />
            </div>
        </>
    );
};

export default Biomass;
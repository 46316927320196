import React, { useEffect, useState, } from 'react';
import ProjectContainer from './ProjectContainer';
import { fetch } from '../../fetch';

const ProjectBusiness = () => {


    const [HomeProject, setHomeProject] = useState([])

    const path1 = '/img/Projects'

    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        const res = await fetch('POST', '/projects/get_projects.php', {
            show: 'show',
            type: 'business'
        })
        // console.log(res)
        const { head, details } = res.items
        const m = head.map(item => (
            {
                title: item.img_title_path,
                list: details.filter(({ project_id }) => project_id === item.project_id).map(i => i.img_path),
                content: {
                    name: item.name,
                    watt: item.power,
                    address: item.location
                }
            }))
        setHomeProject(m)
    }
    return (
        <ProjectContainer
            MainPath={path1}
            list={HomeProject}
            header={'ธุรกิจ'}
        />
    );
};

export default ProjectBusiness;
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { fetch } from '../../fetch';
import Articles from './Articles';

const ArticleDetail = () => {
    const { id, name } = useParams()
    const detailRef = useRef()
    const navigate = useNavigate()
    const [article, setArticle] = useState({})

    useEffect(() => {
        if (id) {
            loadArticleDetail()
        } else {
            navigate('/articles/all', { replace: true })
        }
    }, [])
    const loadArticleDetail = async () => {
        try {
            const res = await fetch('POST', '/articles/get_article_detail.php', { article_id: id })
            setArticle(res.items[0])
            detailRef.current.innerHTML = res.items[0].article_detail
        } catch {
            navigate('/articles/all', { replace: true })
        }
    }

    return (
        <>
            <Helmet>
                <title>{`${article.article_title}  - MEGAWATT ENERGY SOLUTION`}</title>
                <meta
                    name={name}
                    content={article.article_intro}
                />
            </Helmet>
            <div
                style={{
                    width: '80%',
                    margin: '20px auto',
                    maxWidth: '1200px'
                    // textAlign: 'center'
                }}
                className={'font-medium'}
            >
                <h1 className='fs-30'>
                    {article.article_title}
                </h1>
                <div>
                    วันที่ : {moment(article.available_date).format('DD MMMM YYYY')}
                </div>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <img alt='' src={'/img/images/' + article.title_image_path} width={'100%'} />
                </div>
                <br />
                <br />
                <div className='fs-18 font-light' ref={detailRef}>
                </div>
            </div>
            <Articles type='random' />
        </>
    );
};

export default ArticleDetail;
import React, { useState, useRef, useEffect } from 'react';
import {
    CaretRightOutlined
} from '@ant-design/icons'
import '../css/figurEffectLily.css'
import { useNavigate } from 'react-router-dom';
const UtilCard = ({ imgPath, title, goto = '/home' }) => {
    const navigate = useNavigate()
    // const [isVisible, setVisible] = useState(true)
    // const domRef = useRef()

    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => setVisible(entry.isIntersecting))
    //     })
    //     observer.observe(domRef.current);
    //     // return () => {
    //     //     if (domRef.current !== undefined) {
    //     //         observer.unobserve(domRef.current)
    //     //     }
    //     // }
    // }, [])

    return (
        <figure className='effect-lily'>
            <div
                // className={`card-custom fade-in-section ${isVisible ? 'is-visible' : ''}`}
                className={`card-custom`}
                style={{
                    textAlign: 'center',
                    // minHeight: '460px',
                    overflow: "hidden",
                    width: '100%'
                }}
                onClick={() => navigate(goto)}
            // ref={domRef}
            >
                <img src={imgPath} alt="" loading="lazy"/>
                {/* <img src={imgPath} style={{ width: "100%", display: 'flex', justifyContent: 'start' }} alt="" /> */}
                <figcaption style={{ position: 'absolute', width: '100%', bottom: '15%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{
                            // fontFamily: 'Metropolis',
                            display: 'block',
                            color: '#fff',
                            fontSize: '26px',
                            lineHeight: '33px',
                        }}>{title}</span>
                    </div>
                </figcaption>
            </div>
        </figure>
    );
};

export default UtilCard;
import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RenderBusiness } from '../OurBusiness';
import '../ourbusiness.css'
import OverView from './Components';
const Solacell = () => {

    return (
        <>
            <Helmet>
                <title>SOLA FARM - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'เมกะวัตต์ ดำเนินธุรกิจที่เกี่ยวข้องกับพลังงานหมุนเวียนจากพลังงานแสงอาทิตย์ อย่างการติดตั้งโซล่าเซลล์ที่มีทั้งแบบติดตั้งบนพื้นดิน และแบบติดดั้งบนหลังคา รวมถึงการให้บริการติดตั้งและบำรุงรักษาระบบพลังงานแบบครบวงจร ที่มีทีมงานผู้เชี่ยวชาญที่พร้อมให้คำปรึกษาและดูแลการติดตั้งโซล่าเซลล์สำหรับทุกความต้องการ'}
                />
            </Helmet>
            <div
                style={{
                    margin: '20px auto',
                    width: '80%',

                }}
                className={'font-medium'}
            >
                <Row align={'middle'}>
                    <Col xs={24} sm={24} md={6}
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <img src='/img/Icons/Icon-Energy-01.png' alt='solarcell' style={{ width: '60%', maxWidth: '150px' }} loading={'lazy'} />
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                        <div >
                            <h1
                                className='c-gold fs-30'
                            >
                                โรงไฟฟ้าพลังงานแสงอาทิตย์
                            </h1>
                            <h2 className='c-gray fs-18' style={{ display: 'block', lineHeight: '28px', textAlign: 'justify' }}>
                                เมกะวัตต์ ดำเนินธุรกิจที่เกี่ยวข้องกับพลังงานหมุนเวียนจากพลังงานแสงอาทิตย์ อย่างการติดตั้งโซล่าเซลล์ที่มีทั้งแบบติดตั้งบนพื้นดิน และแบบติดดั้งบนหลังคา รวมถงการให้บริการติดตั้งและบำรุงรักษาระบบพลังงานแบบครบวงจร ที่มีทีมงานผู้เชี่ยวชาญที่พร้อมให้คำปรึกษาและดูแลการติดตั้งโซล่าเซลล์สำหรับทุกความต้องการ
                            </h2>
                        </div>
                    </Col>
                </Row>
                <br />
                <OverView
                    imagePath='/img/OurBusiness/the-solar-panels.jpg'
                    finishMW='30.00'
                    inProgress='4.00'
                />
                {/* <div className='div-bg mt-3' style={{ width: '100%', padding: '20px' }}>
                    <Row >
                        <Col sm={24} md={12}>
                            <div className='fs-24' style={{ marginLeft: '10%' }} >
                                ภาพรวมใรการดำเนินงาน
                            </div>
                            <br />
                            <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                                <div style={{ marginRight: 'auto' }}>
                                    ดำเนินการแล้วเสร็จ
                                </div>
                                <div>
                                    30.00 MW
                                </div>
                            </div>
                            <br />
                            <div className='fs-20' style={{ marginLeft: '10%', marginRight: '20%', display: 'flex', justifyContent: 'space-around' }}>
                                <div style={{ marginRight: 'auto' }}>
                                    อยู่ระหว่างการก่อสร้าง
                                </div>
                                <div>
                                    4.00 MW
                                </div>
                            </div>
                        </Col>
                        <Col sm={24} md={12}>
                            <img src='/img/OurBusiness/the-solar-panels.jpg' alt='solar panels' style={{ width: '100%' }} loading="lazy" />
                        </Col>
                    </Row>
                </div> */}
                {/* <img src='/img/Live-Demo.jpg' style={{ width: '100%' }} /> */}
                {/* <div style={{ backgroundColor: '#c6c6c6', height: '500px' }}>
                    Demo
                </div> */}
                <br />
                <div className='fs-24 c-gold mt-5'>
                    การติดตั้งโซล่าเซลล์ มีข้อดีอย่างไร
                </div>
                <div className='fs-18 font-light c-gray' style={{ textAlign: 'justify' }}>
                    การติดตั้งโซล่าเซลล์เป็นอุปกรณ์อิเล็กทรอนิกส์ที่สามารถเปลี่ยนพลังงานแสงอาทิตย์ไปเป็นพลังงานไฟฟ้ากระแสตรง ซึ่งเป็นกระแสไฟฟ้าที่สามารถใช้ได้ทันทีสามารถกักเก็บไฟฟ้าเอาไว้ได้ในรูปแบบของแบตเตอร์รี่ เพื่อเอาไว้ใช้งานในภายหลัง พร้อมทั้งช่วยลดและประหยัดค่าใช้จ่ายด้านไฟฟ้าระบบโซล่าเซลล์เป็นระบบที่สามารถใช้งานได้อย่างยั่งยืน เนื่องจากเป็นแหล่งรับพลังงานจากแสงอาทิตย์ ซึ่งถือเป็นพลังงานหมุนเวียนที่ไม่มีทางหมดไป การติดตั้งโซล่าเซลล์ถือเป็นการลงทุนระยะยาวที่คุ้มค่า เนื่องจากติดตั้งเพียงครั้งเดียว มีอายุการใช้งานยาวนาน
                </div>
                <br />
                <div className='fs-24 c-gold'>
                    ระบบโซล่าเซลล์ของเมกะวัตต์
                </div>
                <div className='fs-18 font-light c-gray' style={{ textAlign: 'justify' }}>
                    พร้อมให้บริการติดตั้งโซล่าเซลล์ ด้วยความเป็นมืออาชีพ พร้อมมีผู้เชียวชาญคอยให้คำปรึกษาเกี่ยวกับการติดตั้งโซล่าเซลล์ทั้งการติดตั้งบนพื้นดิน และการติดตั้งบนหลังคา
                    เพื่อช่วยหาวิธีการใชพลังงานได้อย่างมีประสิทธิภาพได้อย่างยั่งยืน การติดตั้งระบบโซล่าเซลล์ของเมกะวัตต์มีการใช้วัสดุที่มีคุณภาพ
                    ได้มาตรฐาน พร้อมใช้เทคโนโลยีที่ทันสมัยใรการความคุม การติดตั้ง และดูแลรักษาในการใช้พลังงานได้อย่างยั่งยืน
                </div>
                <br />
                <div className='fs-18 font-light c-gray' style={{ textAlign: 'justify' }}>
                    การติดตั้งโซล่าเซลล์ ถือเป็นการใช้แสงอาทิตย์ที่เป็นพลังงานหมุนเวียน มาใช้เป็นแหล่งพลังงานในการผลิตไฟฟ้า สะดวกในการใช้งานเนื่องจากไฟฟ้าที่ได้เป็นไฟฟ้ากระแสตรงที่สามารถใช้งานได้เลย ทำให้เป็นทางเลือกที่ผู้ประกอบการธุรกิจเลือกติดตั้งโซล่าเซลล์
                    กับโรงงานสถานประกอบการกันมากขึ้น และการเลือกบริษัทที่รับติดตั้งอย่างหลากหลาย เพื่อตอบโจทย์กับสถานที่ที่ต้องการติดตั้ง
                    รวมทั้งควรเลือกบริษัทที่มีบริการบำรุงดูแลรักษาตลอดการใช้งานโซล่าเซลล์ หากเกิดปัญหาระหว่างการใช้งาน
                </div>
                <br />
                <div className='fs-18 font-light c-gray' style={{ textAlign: 'justify' }} >
                    การติดตั้งโซล่าเซลล์ ถือเป็นการใช้แสงอาทิตย์ที่เป็นพลังงานหมุนเวียน มาใช้เป็นแหล่งพลังงานในการผลิตไฟฟ้า สะดวกในการใช้งานเนื่องจากไฟฟ้าที่ได้เป็นไฟฟ้ากระแสตรงที่สามารถใช้งานได้เลย ทำให้เป็นทางเลือกที่ผู้ประกอบการธุรกิจเลือกติดตั้งโซล่าเซลล์
                    กับโรงงานสถานประกอบการกันมากขึ้น และการเลือกบริษัทที่รับติดตั้งอย่างหลากหลาย เพื่อตอบโจทย์กับสถานที่ที่ต้องการติดตั้ง
                    รวมทั้งควรเลือกบริษัทที่มีบริการบำรุงดูแลรักษาตลอดการใช้งานโซล่าเซลล์ หากเกิดปัญหาระหว่างการใช้งาน

                </div>
                <div className='fs-18 font-light c-gray'>
                    ระบบพลังงานแสงอาทิตย์ แบ่งเป็น 3 ชนิด
                    <ol>
                        <li>ระบบเชื่อมต่อโครงข่าย (Ongrid)</li>
                        <li>ระบบไม่เชื่อมต่อโครงข่าย (Off Grid)</li>
                        <li>ระบบผสมผสาน (Hybrid System)</li>
                    </ol>
                </div>
                <RenderBusiness imageWidth='30%' />
            </div>
        </>

    );
};

export default Solacell;
import React, { useEffect } from 'react';
import {
    Row,
    Col,
} from 'antd'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Energy = () => {


    return (
        <>
            <Helmet>
                <title>ENERGY BUSINESS - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'ด้วยประสบการณ์การพัฒนาและบริหารโรงไฟฟ้ามามากกว่า 30 โครงการ หรือมากกว่า 14 กิกะวัตต์ เมกะวัตต์ได้ลงทุนและดำเนินการโครงการโรงไฟฟ้า ทั้งโรงไฟฟ้าก๊าซธรรมชาติและโรงไฟฟ้าพลังงานหมุนเียน ซึ่งมีสัญญาซื้อขายไฟระยะยาวกับภาครัฐ หรือผู้รับซื้อไฟเอกชนที่มีความมั่นคง นอกจากนี้บริษัทฯ ยังมุ่งพัฒนาธุรกิจก๊าซธรรมชาติซึ่งถือเป็นการต่อยอดธุรกิจไปยังอุตสาหกรรมต้นน้ำน่อเนื่องจากธุรกิจไฟฟ้า'}
                />
            </Helmet>

            <img src='/img/Banner/Business.jpg' style={{
                width: '100%',
            }} loading={'lazy'} />
            <div
                style={{
                    width: '90%',
                    margin: '20px auto',
                    textAlign: 'center'
                }}
                className={'font-medium'}
            >
                <h1
                    className='fs-38 c-gold '
                >
                    ธุรกิจพลังงาน
                </h1>
                <div
                    className='fs-16 c-gray'
                    style={{
                        display: 'block',
                        lineHeight: '28px'
                    }}
                >
                    ด้วยประสบการณ์การพัฒนาและบริหารโรงไฟฟ้ามากกว่า 200 โครงการ ทั้งภาครัฐและภาคเอกชน นอกจากนี้บริษัทฯยังมุ่งมั่นพัฒนาธุรกิจ
                    <br />
                    พลังงานทางเลือกที่บริษัทมุ่งมั่นดำเนินกิจการสู่ความเป็นผู้นำด้านธุรกิจพลังงาน และธุรกิจพลังงานสะอาดเพื่อเป็นอีกหนึ่งแรงผลักดันในการส่งเสริม
                    <br />
                    Net Zero ภายในประเทศไทย เราให้ความสำคัญกับการทำธุรกิจพลังงานอย่างยั่งยืนเพื่อสร้างสมดุลทั้งในด้านสังคม ด้านสิ่งแวดล้อม และผลตอบแทน
                    <br />
                    ผ่านการสร้างสรรค์และผลักดันโครงการธุรกิจพลังงานหมุนเวียนและพลังงานทางเลือก และลงทุนในบริษัทเกี่ยวกับพลังงานทดแทนในรูปแบบต่าง ๆ
                    <br />
                    เพื่อรองรับการดำรงชีพและกิจกรรมอื่น ๆ โดยปราศจากการสร้างผลกระทบต่อสภาพแวดล้อมและระบบนิเวศของโลก
                </div>
                <Row justify={'center'} align={'bottom'} className='c-gray fs-30 font-medium mt-3' gutter={[20]} >
                    {/* <Col md={8} sm={24} xs={24} > */}
                    <RenderIcon
                        imgPath={'/img/Icons/Icon-Energy-01.png'}
                        goto={'/business/energy/solafarm'}
                        // size={'130px'}

                        title={(
                            <>
                                <span>
                                    โครงการโรงไฟฟ้า
                                </span>
                                <br />
                                <span>
                                    พลังงานแสงอาทิตย์
                                </span>
                            </>
                        )}
                    />
                    {/* <Icondiv>
                            <span className='icon-hover' onClick={() => navigate('/business/energy/solacell')}>
                                <span>
                                    โครงการโรงไฟฟ้า
                                </span>
                                <br />
                                <span>
                                    พลังงานแสงอาทิตย์
                                </span>
                            </span>
                        </Icondiv> */}
                    {/* </Col>
                    <Col md={8} sm={24} xs={24} > */}
                    <RenderIcon
                        imgPath={'/img/Icons/Icon-Energy-02.png'}
                        goto={'/business/energy/biomass'}
                        // size={'130px'}

                        title={(
                            <>
                                <span>
                                    โครงการโรงไฟฟ้าชีวมวล
                                </span>
                                <br />
                                <span>
                                    (พลังงานหมุนเวียน)
                                </span>
                            </>
                        )}
                    />
                    {/* <Icondiv>
                            <span className='icon-hover'
                                onClick={() => navigate('/business/energy/biomass')}
                            >
                                <span>
                                    โครงการโรงไฟ้ฟ้าชีวมวล
                                </span>
                                <br />
                                <span>
                                    (พลังงานหมุนเวียน)
                                </span>
                            </span>
                        </Icondiv> */}
                    {/* </Col>
                    <Col md={8} sm={24} xs={24} > */}
                    <RenderIcon
                        imgPath={'/img/Icons/Icon-Energy-03.png'}
                        goto={'/business/energy/windpower'}
                        // size={'120px'}
                        title={(
                            <>
                                <span>
                                    โครงการโรงไฟฟ้าพลังงานลม
                                </span>
                                <br />
                                <span>
                                    (พลังงานหมุนเวียน)
                                </span>
                            </>
                        )}
                    />
                    {/* <Icondiv>
                            <span className='icon-hover'
                                onClick={() => navigate('/business/energy/windpower')}
                            >
                                <span>
                                    โครงการโรงไฟฟ้าพลังงานลม
                                </span>
                                <br />
                                <span>
                                    (พลังงานหมุนเวียน)
                                </span>
                            </span>
                        </Icondiv> */}
                    {/* </Col> */}
                </Row>
            </div>

        </>
    );
};

const RenderIcon = ({ title, imgPath, goto, size = '130px' }) => {
    const navigate = useNavigate()
    return (
        <div className='icon-hover3'
            style={{ display: 'flex', flexDirection: 'column', marginLeft: '50px', marginRight: '50px', justifyContent: 'flex-end', alignItems: 'center' }}
            onClick={() => {
                navigate(goto)
            }}>
            <img src={imgPath} style={{ width: size }} loading={'lazy'} />
            <span
                style={{ fontSize: '60%' }}
            >
                {title}
            </span>
        </div>
    )
}


export default Energy;
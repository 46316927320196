import React, { useState, useEffect } from 'react';
import ProjectContainer from './ProjectContainer';
import { useLocation } from 'react-router-dom';
import { fetch } from '../../fetch';
const ProjectAll = () => {
    const [HomeProject, setHomeProject] = useState([])
    const path1 = '/img/Projects'
    const {pathname} = useLocation()
    useEffect(() => {
        loadData()
        // console.log(pathname.split('/')[2])
    }, [])

    const loadData = async () => {
        const res = await fetch('POST', '/projects/get_projects.php', {
            show: 'all',
            type: pathname.split('/')[2]
        })
        // console.log(res)
        const { head, details } = res.items
        const m = head.map(item => (
            {
                title: item.img_title_path,
                list: details.filter(({ project_id }) => project_id === item.project_id).map(i => i.img_path),
                content: {
                    name: item.name,
                    watt: item.power,
                    address: item.location
                }
            }))
        setHomeProject(m)
    }
    return (
        <ProjectContainer
            MainPath={path1}
            list={HomeProject}
            header={pathname.split('/')[2] === 'home' ? 'บ้าน' : 'ธุรกิจ' }
            type={'all'}
        />
    );
};

export default ProjectAll;
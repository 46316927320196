import React, { useEffect } from 'react';
import {
    Row,
    Col,
} from 'antd'
import { Helmet } from 'react-helmet';
import OverView from './Components';
import { RenderBusiness } from '../OurBusiness';


const Windpower = () => {

    return (
        <>
            <Helmet>
                <title>WIND - MEGAWATT ENERGY SOLUTION</title>
                <meta
                    name={'description'}
                    content={'เมกะวัตต์ ดำเนินโครงการโรงไฟฟ้าพลังงานลมในทะเลจำนวน 2 โครงการในต่างประเทศ ประกอบด้วยโครงการโรงไฟฟ้าพลังงานลมในทะเล Mekong Wind ที่ประเทศเวียดนาม ด้วยกำลังผลิตไฟฟ้าติดตั้งรวม 128.0 เมกะวัตต์'}
                />
            </Helmet>
            <div
                style={{
                    margin: '20px auto',
                    width: '80%',

                }}
                className={'font-medium'}
            >
                <Row align={'middle'}>
                    <Col xs={24} sm={24} md={6}
                        style={{ textAlign: 'center' }}
                    >
                        <img src='/img/Icons/Icon-Energy-03.png' alt='' style={{ width: '60%', maxWidth: '150px' }} loading={'lazy'} />
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                        <h1
                            className='c-gold fs-30'
                        >
                            โครงการโรงไฟฟ้าพลังงานลม
                        </h1>
                        <div className='c-gray fs-18' style={{ textAlign: 'justify' }}>
                            เราดำเนินการออกแบบ,ติดตั้ง และบำรุงรักษา รวมถึงให้คำปรึกษาด้านพลังงานลม กังหันลม เป็นอุปกรณ์ก่อให้เกิดพลังงานจลน์จากกระแสลม และเปลี่ยนให้เป็นพลังงานกล จากนั้นนำพลังงานกลมาใช้ประโยชน์กับเครื่องกำเนิดไฟฟ้าที่เชื่อมต่ออยู่กับแกนหมุนของกังหันลม จ่ายกระแสไฟฟ้าผ่านระบบควบคุมไฟฟ้า และจ่ายกระแสไฟฟ้าเข้าสู่ระบบต่อไป โดยปริมาณไฟฟ้าที่ผลิต ได้จะขึ้นอยู่กับความเร็วของลม ความยาวของใบพัด และสถานที่ติดตั้งกังหันลม
                        </div>
                    </Col>
                </Row>
                <br />
                <OverView
                    imagePath='/img/OurBusiness/wind-powered.jpg'

                />
                <br />
                <div className='fs-18 c-gray font-light mt-5'>
                    ชนิดของกังหันลม
                    โดยทั่วไปกังหันลมแบ่งออกเป็น 2 ชนิด ตามแกนหมุนของกังหันลม ได้แก่
                    <ol>
                        <li>กังหันลมแกนหมุนแนวตั้ง (Vertical Axis Wind Turbine) เป็นกังหันลมที่มีแกนหมุน และใบพัดตั้งฉากกับการเคลื่อนที่ของลมในแนวราบ</li>
                        <li>กังหันลมแกนหมุนแนวนอน (Horizontal Axis Wind Turbine) เป็นกังหันลมที่มีแกนหมุนขนานกับการเคลื่อนที่ของลมในแนวราบ โดยมีใบพัดเป็นตัวตั้งฉากรับแรงลม</li>
                    </ol>
                </div>
                <RenderBusiness imageWidth='30%' />
            </div>
        </>
    );
};

export default Windpower;